@import url("./fonts.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

/*@import "tailwind/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; */

body {
  margin: 0;
  font-family: Karla, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark {
  background: #12101e;
}
.light {
  background-color: #f6f8fc;
}

.nav-dark {
  background: #211c3d;
}

/* .calc-dark {
  background: linear-gradient(rgba(46, 38, 82, 0.6714) 0%, #151129 100%);
  transition: background-color 0.5s ease-in-out;
}

.calc-light {
  background: linear-gradient(#fafbff 0%, rgba(243, 245, 253, 0.2151) 100%);
  transition: background-color 0.5s ease-in-out;
} */

.icon-box-dark {
  background: linear-gradient(44deg, #f0e9ff -8.99%, #6b38fb 80.81%);
  border: 0.5px solid #ffffff52;
  border-radius: 8px;
}

.icon-box-light {
  background: linear-gradient(270deg, #6b38fb -38.99%, #a68ce7 60.81%);
  border-radius: 8px;
}

html {
  scroll-behavior: smooth !important;
  overflow-x: hidden !important;
  scroll-padding-top: 6rem !important;
}

body {
  font-family: "Karla-Medium";
}

.shd {
  box-shadow: 0 0 20px 0 rgba(128, 133, 155, 0.2);
}

.parent {
  position: relative;
  cursor: pointer;
}

.menu > li {
  display: flex;
  justify-content: center;
}

.child {
  opacity: 0;
  position: absolute;
  overflow: hidden;
  display: none;

  border-radius: 8px;
  transition: all 0.5s;
  top: 90px;
  @apply shadow-lg;
}

.trigger-enter .child {
  display: block;
}

.trigger-enter-active .child {
  opacity: 1;
}

.dropdownBackground {
  width: 100px;
  height: 100px;
  position: absolute;
  transition: all 0.3s, opacity 0.1s, translate 0.2s;
  transform-origin: 50% 0%;
  display: none;
  justify-content: center;
  border-radius: 8px;
  opacity: 0;
  @apply shadow-lg;
}

.dropdownBackground.open {
  display: flex;
  opacity: 1;
}

.arrow {
  position: absolute;
  top: -5px;
  left: 50%;
  right: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  @apply shadow-lg;
}

@keyframes moveL-R {
  0% {
    transform: translateX(-20%);
  }
  20% {
    transform: translateX(0);
  }
}

@keyframes moveR-L {
  0% {
    transform: translateX(20%);
  }
  20% {
    transform: translateX(0%);
  }
}

.boxL-R {
  animation: moveL-R 0.8s ease-in-out;
}

.boxR-L {
  animation: moveR-L 0.8s ease-in-out;
}

.searchInput {
  /* position: relative; */
  cursor: pointer;
}

.searchItem {
  /* position: absolute; */
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  @apply shadow-lg;
}

.searchInput:hover .searchItem {
  display: block;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate-fade-in-delay img {
  animation-name: fade-in;
  animation-duration: 1s;
}

.animate-fade-in-delay {
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

.modal-container::-webkit-scrollbar {
  width: 5px;
}

.modal-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.modal-container::-webkit-scrollbar-thumb {
  background: #888;
}

.modal-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.transform_left {
  transform-origin: left;
}
.transform_right {
  transform-origin: right;
}
.transform_top {
  transform-origin: top;
}
.transform_bottom {
  transform-origin: bottom;
}
.transform_center {
  transform-origin: center;
}

.appear_animation-2s {
  animation: appear 0.75s ease-in-out !important;
  animation-delay: 0.2s;
}

.appear_animation-4s {
  animation: appear 0.75s ease-in-out !important;
  animation-delay: 0.4s;
}

.appear_animation-6s {
  animation: appear 0.75s ease-in-out !important;
  animation-delay: 0.6s;
}

.appear_animation-8s {
  animation: appear 0.75s ease-in-out !important;
  animation-delay: 0.8s;
}
@font-face {
  font-family: "Karla-Bold";
  font-style: Bold;
  font-weight: 700;
  src: url("/public/fonts/Karla-Bold.ttf");
}

@font-face {
  font-family: "Karla-Regular";
  font-style: Regular;
  font-weight: 400;
  src: url("/public/fonts/Karla-Regular.ttf");
}

@font-face {
  font-family: "Karla-Medium";
  font-style: Medium;
  font-weight: 500;
  src: url("/public/fonts/Karla-Medium.ttf");
}

@font-face {
  font-family: "Karla-Light";
  font-style: Light;
  font-weight: 300;
  src: url("/public/fonts/Karla-Light.ttf");
}

.container {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
}

@media (prefers-reduced-motion) {
}
